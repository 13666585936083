require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
import GLightbox from '../node_modules/glightbox/dist/js/glightbox'; 
import tabs from './modules/tabs';
import tabsHover from './modules/tabs-hover';
import menu from './modules/menu';
import modal from './modules/modal';
import gridSlider from './modules/grid-slider';

window.addEventListener('DOMContentLoaded', () => { 
  menu('.header__burger', '.header-flex', 'active');
  modal('[data-modal]', '.modal');
  try {
    tabsHover('.hero-heads', '.hero-menu', '.hero-headers', 'active');
  } catch (error) {}
  try {
    tabs('.portfolio-head', '.portfolio-tabcontent', '.portfolio-headers', 'active');
  } catch (error) {}
  try {
    gridSlider();
  } catch (error) {}
  
  // modal('[data-modal]', '.modal');

  try {
    const slider = tns({
      container: '.documents-slider',
      items: 1,
      slideBy: 1, 
      autoplay: false,
      mouseDrag: true,
      controls: true,
      nav: false,
      controlsContainer: "#customize-controls",
      responsive: {
        1024: {
          items: 4,
          gutter: 30,
        }
      }  
    });
  } catch (error) {}
  try {
    const sliderAbout = tns({
      container: '.about-slider',
      items: 1,
      slideBy: 1, 
      autoplay: false,
      mouseDrag: true,
      controls: true,
      nav: false,
      controlsContainer: "#about-controls"
    });
  } catch (error) {}
  try {
    $('.portfolio-slider').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      infinite: true,
      dots: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  } catch (error) {}

  const lightbox = GLightbox({
    'selector': '.glightbox'
  });
});